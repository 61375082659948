import { IconType } from 'react-icons';
import { FaBriefcase, FaDesktop, FaHeartbeat, FaHome } from "react-icons/fa";
import { IoNewspaperSharp } from 'react-icons/io5';
import { SiAboutdotme } from 'react-icons/si';

export interface NavItem {
    label: string;
    path: string;
    icon?: IconType;
    subItems?: NavItem[];
}

export const navigationData: NavItem[] = [
    {
        label: 'Home',
        icon: FaHome,
        path: '/',
    },
    {
        label: "Reasons To Live",
        icon: FaHeartbeat ,
        path: '/reasons-to-live'
    },
    {
        label: 'Projects',
        icon: FaBriefcase ,
        path: '/projects',
        subItems: [
            {
                label: 'OxInventory Editor',
                path: '/editor'
            },
            {
                label: 'DabzSite',
                path: '/projects/dabzsite'
            },
            {
                label: 'Web Projects',
                path: '/projects/web'
            },
            {
                label: 'Mobile Projects',
                path: '/projects/mobile'
            },
            {
                label: 'Desktop Projects',
                path: '/projects/desktop'
            },
            {
                label: 'More Projects',
                path: '/projects'
            }
        ]
    },
    {
        label: 'Blog',
        path: '/blog',
        icon: IoNewspaperSharp,
        subItems: [
            {
                label: 'Lua Basics',
                path: '/blog/lua-basics'
            },
            {
                label: 'Useful Misc',
                path: '/blog/useful-misc'
            },
            {
                label: 'More Blog Posts',
                path: '/blog'
            }
        ]
    },
    {
        label: 'About',
        path: '/about',
        icon: SiAboutdotme ,
    }
];