import { useState } from 'react';
import { Link } from 'react-router';
import { NavItem, navigationData } from '../types/nagivation';

const NavLink = ({ item }: { item: NavItem }) => {
    const hasSubItems = item.subItems && item.subItems.length > 0;

    return (
        <div className="relative group">
            {hasSubItems ? (
                <div>
                    <button
                        className="flex items-center px-4 py-2 text-gray-300 hover:text-purple-400 transition-all duration-200 ease-in-out
                        relative after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-0 hover:after:w-full after:bg-purple-400 after:transition-all after:duration-200"
                    >
                        {item.icon && item.icon({ className: "mr-2" })}
                        {item.label}
                        <svg
                            className="ml-2 h-4 w-4 transition-transform group-hover:rotate-180 duration-200"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                        >
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                        </svg>
                    </button>
                    <div className="absolute left-0 w-48 bg-gray-950/95 backdrop-blur-sm rounded-lg py-1 z-10 hidden group-hover:block pt-2 
    before:content-[''] before:absolute before:top-[-10px] before:left-0 before:right-0 before:h-[10px]
    border border-gray-700 transition-all duration-200 ease-in-out transform origin-top scale-95 group-hover:scale-100
    shadow-lg shadow-purple-500/10
    inset-shadow-lg inset-shadow-purple-500/5
    ring-1 ring-gray-700/50
    inset-ring-1 inset-ring-purple-400/10">
                        {item.subItems!.map((subItem) => (
                            <Link
                                key={subItem.path}
                                to={subItem.path}
                                className="block px-4 py-2 text-sm text-gray-300 hover:bg-gray-900 hover:text-purple-400 transition-colors duration-150"
                            >
                                {subItem.label}
                            </Link>
                        ))}
                    </div>
                </div>
            ) : (
                <Link
                    to={item.path}
                    className="flex items-center px-4 py-2 text-gray-300 hover:text-purple-400 transition-all duration-200 ease-in-out
                    relative after:absolute after:bottom-0 after:left-0 after:h-0.5 after:w-0 hover:after:w-full after:bg-purple-400 after:transition-all after:duration-200"
                >
                    {item.icon && item.icon({ className: "mr-2" })}
                    {item.label}
                </Link>
            )}
        </div>
    );
};

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <nav className="sticky top-4 mx-4 z-50 
    bg-gray-950/95 backdrop-blur-sm 
    rounded-xl
    shadow-lg shadow-purple-500/10
    inset-shadow-lg inset-shadow-purple-500/5
    ring-1 ring-gray-700/50
    inset-ring-1 inset-ring-purple-400/10
    border border-gray-800">
            <div className="max-w-7xl mx-auto px-4">
                <div className="flex justify-between h-16">
                    <div className="flex items-center">
                        <Link to="/" className="text-xl font-bold bg-gradient-to-r from-purple-400 to-pink-400 bg-clip-text text-transparent">
                            Dabz's Site
                        </Link>
                    </div>

                    {/* Mobile menu button */}
                    <div className="flex items-center md:hidden">
                        <button
                            onClick={() => setIsOpen(!isOpen)}
                            className="inline-flex items-center justify-center p-2 rounded-md text-gray-300 hover:text-purple-400 hover:bg-gray-800 transition-colors duration-200"
                        >
                            <svg
                                className="h-6 w-6"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke="currentColor"
                            >
                                {isOpen ? (
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                ) : (
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                                )}
                            </svg>
                        </button>
                    </div>

                    {/* Desktop menu */}
                    <div className="hidden md:flex md:items-center md:space-x-4">
                        {navigationData.map((item) => (
                            <NavLink key={item.path} item={item} />
                        ))}
                    </div>
                </div>
            </div>

            {/* Mobile menu */}
            {isOpen && (
                <div className="md:hidden absolute w-full bg-gray-900/95 backdrop-blur-sm border-b border-gray-800">
                    <div className="px-2 pt-2 pb-3 space-y-1">
                        {navigationData.map((item) => (
                            <NavLink key={item.path} item={item} />
                        ))}
                    </div>
                </div>
            )}
        </nav>
    );
};



export default Navbar;
